import React, { Component } from 'react';
import firebase from '../Firebase';

import { withRouter } from 'react-router';

import { PersonCondensed } from '../components/Person';

class Section extends Component {
  state = {
    people:[],
    section:null,
    company:null,
    loading: true,
  }
  async componentDidMount() {
    const { sectionId, companyId } = this.props.match.params;
    const sectionRef = firebase.dbRef.collection('companies').doc(companyId).collection('avdelningar').doc(sectionId);
    const section = await sectionRef.get().then(doc => { return doc.data() });
    const company = await firebase.dbRef.collection('companies').doc(companyId).get().then(doc => { return doc.data() });
    this.setState({section: section, company: company, loading: false});
    firebase.dbRef.collection('people')
        .where('department.sectionId', '==', sectionId)
        .onSnapshot(snap => {
      const people = snap.docs.map(doc => {
        let person = doc.data();
        console.log('person', person);
        person.id = doc.id;
        return person;
      });
      this.setState({people:people});
    });
  }

  render() {
    const { company, section, people, loading } = this.state;
    const peopleSort = people.sort(function(a, b){
      if(a.lastName < b.lastName) { return -1; }
      if(a.lastName > b.lastName) { return 1; }
      return 0;
    });
    return (
      <div>
        <div>
          {!loading ? (<h2>{company.name} / {section.name}</h2>) : (<h3>...</h3>)}
        </div>
        <div>
          <h3>{people.length} Personer:</h3>
          {peopleSort.map(person => (
            <PersonCondensed person={person} />
          ))}
        </div>
      </div>
    )
  }
}

export default withRouter(Section);
