import React, { Component } from 'react';
import firebase from '../Firebase';

import '../css/addperson.css';
import '../css/search.css';

//MaterialUI

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';

//algolia
import algoliasearch from 'algoliasearch';

//search
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

//person
import { PersonSearch } from '../components/Person';

//router
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-start',
    width: 400,
  },
  rootperson: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-start',
    width: 800,
  },
  gridRoot: {
    flexGrow:1
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const SearchField = ({searchString, change}) => {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="..."
        value={searchString}
        onKeyPress={(event) => { if (event.key === 'Enter') { event.preventDefault()}}}
        onChange={(e) => { change(e.target.value) }}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

const client = algoliasearch('RZ6G4EILOV', 'b8c3c705f193ac4290cb7666102ec378');

function base64encode(str) {
  let encode = encodeURIComponent(str).replace(/%([a-f0-9]{2})/gi, (m, $1) => String.fromCharCode(parseInt($1, 16)))
  return btoa(encode)
}
function base64decode(str) {
  let decode = atob(str).replace(/[\x80-\uffff]/g, (m) => `%${m.charCodeAt(0).toString(16).padStart(2, '0')}`)
  return decodeURIComponent(decode)
}


class Search extends Component {

  state = {
    searchString: '',
    companies:[],
    selectedCompany:'',
    selectedSection:'Alla',
    searchIndex:null,
    hits: [],
    page: 0,
    nbPages: 0,
  }
  componentDidMount() {
    if (this.props.searchState) {
      this.setState(JSON.parse(this.props.searchState), () => this.encodeState());
      this.loadCompanies(false);
    } else {
      const { match } = this.props;
      const { lastSearch } = match.params;
      if (lastSearch) {
        const search = base64decode(lastSearch);
        const states = JSON.parse(search);
        this.setState({...states}, () => {
          this.loadCompanies();
        });
      }else {
        this.loadCompanies();
      }
    }
  }
  makeSearch = (pageNr = 0) => {
    const { searchIndex, searchString, selectedSection } = this.state;
    // window.location = '/search/query='+searchString;
    if (searchIndex) {
      let filters ='';
      if (selectedSection !== 'Alla') {
        filters = `departmentName:"${selectedSection.name}"`
      }
      searchIndex.search({ query: searchString, page:pageNr, filters:filters }, (err, { hits, nbPages, page, nbHits } = {}) => {
        if (err) throw err;
        this.setState({hits:hits, nbPages: nbPages, page:page, nbHits:nbHits}, () => {
          this.encodeState();
        });
      });
    }
  }
  encodeState = () => {
    this.props.setSearchState(JSON.stringify(this.state));
    const { searchString, selectedSection, selectedCompany, page } = this.state;
    const searchState = base64encode(JSON.stringify({
      searchString:searchString,
      selectedSection: selectedSection,
      selectedCompany: selectedCompany,
      page: page
    }));
    console.log('SEARCH ENCODE', searchState);
    this.props.history.replace('/search/'+searchState);
  }
  loadCompanies = (search = true) => {
    firebase.dbRef.collection('companies').onSnapshot(snapshot => {
      const promises = snapshot.docs.map(async (doc) => {
        const data = doc.data();
        const sections = [];
        await firebase.dbRef.collection('companies').doc(doc.id).collection('avdelningar').get().then(snap => {
          snap.forEach(doc => {
            const sectionData = doc.data();
            sectionData.sectionId = doc.id;
            sections.push(sectionData);
          });
        });
        const company = {
          name: data.name,
          region: data.region,
          companyId: doc.id,
          sections: sections
        };
        return company;
      });
      Promise.all(promises).then(results => {
        this.setState({companies:results});
        if (firebase.internalCompany) {
          results.forEach(result => {
            if (result.companyId === firebase.internalCompany) {
              const index = client.initIndex(result.companyId);
              this.setState({selectedCompany:result, searchIndex:index}, () => {
                if (search) {
                  this.makeSearch(this.state.pageNr)
                }
              });
            }
          })
        }
      })
    });
  }
  render() {
    return(
      <div>
        <div className='headerSearchPage'>
          <ChooseCompany
            companies={this.state.companies}
            selectedCompany={this.state.selectedCompany}
            changeCompany={(e) => {
              const index = client.initIndex(e.target.value.companyId);
              this.setState({selectedCompany: e.target.value, searchIndex: index})
            }}
          />
          {this.state.selectedCompany !== '' &&
            <ChooseSection
              state={this.state}
              changeSection={(e) => this.setState({selectedSection: e.target.value}, () => this.makeSearch())}
            />
          }
        </div>
        <div>
          <h2>Sök person</h2>
          <SearchField searchString={this.state.searchString} change={string => {
            this.setState({searchString:string}, () => {
              if(this.searchWaiting)
                  clearTimeout(this.searchWaiting);
              this.searchWaiting = setTimeout(() => {
                  this.searchWaiting = null;
                  this.makeSearch();
              }, 500);
            });
          }} />
        </div>
        <div className='results'>
          <div style={{display: 'flex'}}>
            <Pagination count={this.state.nbPages} page={this.state.page+1}
              onChange={(event, value) => {
                this.makeSearch(value-1);
              }}
              />
            <Typography style={{paddingLeft:70}}>{this.state.nbHits} träffar</Typography>
          </div>

          {
            this.state.hits.map(hit => (<PersonSearch person={hit} key={hit.objectID} />))
          }
          <br /><br />
          <Pagination count={this.state.nbPages} page={this.state.page+1}
            onChange={(event, value) => {
              this.makeSearch(value-1);
              window.scrollTo(0,0);
            }}
            />
        </div>
      </div>
    );
  }
}

const ChooseCompany = ({companies, selectedCompany, changeCompany}) => (
  <div className='textFieldWrapper'>
    <TextField
      select
      label="Företag"
      value={selectedCompany}
      onChange={(e) => changeCompany(e)}
      margin="normal"
      variant="outlined"
    >
      {companies.map(option => (
        <MenuItem className='selectMenu-item' key={option.name} value={option}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  </div>
)

const ChooseSection = ({state, changeSection}) => (
  <div className='textFieldWrapper'>
    <TextField
      select
      label="Avdelning"
      value={state.selectedSection}
      onChange={(e) => changeSection(e)}
      margin="normal"
      fullWidth={true}
      variant="outlined"
    >
      <MenuItem className='selectMenu-item' key='Alla' value='Alla'>
        Alla
      </MenuItem>
      {state.selectedCompany.sections.map(option => (
        <MenuItem className='selectMenu-item' key={option.name} value={option}>
          {option.name ? option.name : option}
        </MenuItem>
      ))}
    </TextField>
  </div>
)

export default withRouter(Search);
