import React, { Component } from 'react';
import firebase from '../Firebase';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withRouter } from 'react-router';


const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});


class CompanySection extends Component {

  state = {
    companies: []
  }

  componentDidMount() {
    firebase.dbRef.collection('companies').onSnapshot(snapshot => {
      const promises = snapshot.docs.map(async (doc) => {
        const data = doc.data();
        const size = await doc.ref.collection('persons').get().then((snap) => {
          return snap.size;
        });
        const company = {
          name: data.name,
          region: data.region,
          id: doc.id,
          size:size
        };
        return company;
      });
      Promise.all(promises).then(results => {
        this.setState({companies:results});
      })
    });
  }

  chooseSection = (company, section) => {
    const { history } = this.props;
    history.push('section/'+company.id+'/'+section.id);
  }

  render() {

    return(
      <div>
        <div>
          <h3>Företag/Avdelning</h3>
        </div>
        <div>
          {
            this.state.companies.map(company => (
              <Company company={company} key={company.id} chooseSection={this.chooseSection} />
            ))
          }
        </div>
      </div>
    );
  }
}

class Company extends Component {
  state = {
    sections: []
  };
  componentDidMount() {
    firebase.dbRef.collection('companies').doc(this.props.company.id).collection('avdelningar').onSnapshot(snap => {
      const promises = snap.docs.map(async(doc) => {
        const sectionData = doc.data();
        const size = await firebase.dbRef.collection('people')
            .where('department.sectionId', '==', doc.id).get().then((snap) => {
          return snap.size;
        });
        sectionData.id = doc.id;
        sectionData.size = size;
        return sectionData;
      });
      Promise.all(promises).then(res => {
        this.setState({sections: res});
      });
    });
  }

  render() {
    const classes = useStyles;
    const { company } = this.props;
    const sections = this.state.sections.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })
    return(
      <div key={company.id}>
        <Paper className={classes.root}>
          <div style={{padding:10}}>
            <div style={{float:'left'}}>
              <h3>{company.name}</h3>{company.size} medlemmar
            </div>
            <div style={{float:'right'}}>
              <AddPopUp companyId={company.id} companyName={company.name} />
            </div>
          </div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Avdelning</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sections.map(section => (
                <TableRow key={section.name} hover={true} onClick={() => this.props.chooseSection(company, section)}>
                  <TableCell component="th" scope="row">
                    {section.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {section.size} personer
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

const AddPopUp = ({ companyId, companyName }) => {

  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    firebase.dbRef.collection('companies').doc(companyId).collection('avdelningar').add({name: text}).then(() => {
      setOpen(false);
    });
  }
  return(
    <div>
      <Button variant="outlined" color='primary' onClick={handleClickOpen}>Lägg till avdelning</Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Lägg till avdelning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lägg till en avdelning i {companyName}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Avdelningsnamn"
            value={text}
            onChange={e => setText(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Lägg till
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withRouter(CompanySection);
