import React, { Component } from 'react';

import firebase from '../Firebase.js';

//MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Img from 'react-fix-image-orientation';

import { withRouter } from 'react-router';

import NumberFormat from 'react-number-format';

//import Input from '@material-ui/core/Input';

// NPM INSTALLED
//import MaskedInput from 'react-text-mask';


import '../css/addperson.css';

const InputPhone = inputProps => (
  <TextField
    label='Telefonnummer'
    variant="outlined"
    className='textField'
    margin="normal"
    type="string"
    {...inputProps} />
)
const InputBirthday = inputProps => (
  <TextField
    label='Födelsedatum'
    variant="outlined"
    className='textField'
    margin="normal"
    placeholder='ÅÅÅÅ MM DD'
    {...inputProps} />
)

// const operatorLevel = [
//   {
//     value: 'Nivå 1',
//     label: 'Nivå 1',
//   },
//   {
//     value: 'Nivå 2',
//     label: 'Nivå 2',
//   },
//   {
//     value: 'Nivå 3',
//     label: 'Nivå 3',
//   },
//   {
//     value: 'Nivå 4',
//     label: 'Nivå 4',
//   },
// ];

const textAreas = [
  {
    type:'fill'
  },
  {
    type:'fill'
  },
  {
    type:'previewImage'
  },
  {
    name: 'Ladda upp bild',
    state: 'photoURL',
    multiline: false,
    type: 'image'
  },
  {
    type:'fill'
  },
  {
    type:'fill'
  },
  {
    type:'fill'
  },
  {
    type:'fill'
  },
  {
    name: 'Förnamn',
    state: 'firstName',
    multiline: false,

  },
  {
    name: 'Efternamn',
    state: 'lastName',
    multiline: false,
  },
  {
    name: 'Titel',
    state: 'role',
    multiline: false,
  },
  {
    name: 'Ålder',
    state: 'age',
    type:'age',
    multiline: false,
  },
  {
    name: 'Födelsedatum',
    state: 'birthday',
    multiline: false,
    type: 'formated',
    format: '#### ## ##',
    input: InputBirthday,
  },
  {
    name: 'Fyra sista',
    state: 'fourLast',
    multiline: false,
    placeholder: 'XXXX',
    class: 'textFieldSmall'
  },
  {
    type:'selectCompany'
  },
  {
    type:'selectSection'
  },
  {
    name: 'Telefonnummer',
    state: 'phoneNumber',
    multiline: false,
    type: 'formated',
    format: '###-### ## ##',
    input: InputPhone,
  },
  {
    name: 'E-post',
    state: 'email',
    multiline: false,
  },
  {
    name: 'Profil och sammanfattning',
    state: 'profile',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Erfarenheter',
    state: 'experiences',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Kunskaper/Lean',
    state: 'lean',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Certifieringar',
    state: 'certificates',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Egenskaper',
    state: 'abilities',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Färdigheter',
    state: 'skills',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Mål, utveckling och roller',
    state: 'development',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },
  {
    name: 'Arbetstider',
    state: 'worktimes',
    type: 'large',
    class: 'textFieldLarge',
    multiline: true,
    //rows: 3,
  },

]

class AddPerson extends Component {

  state = {
    errors:{},
    interviewDate:'',
    companies:[],
    selectedCompany:'',
    selectedSection:'',
    firstName: '',
    lastName: '',
    birthday: '',
    phoneNumber: '',
    email: '',
    experiences: '',
    abilities: '',
    certificates: '',
    development: '',
    lean: '',
    bossName: '',
    bossPhoneNumber: '',
    titel: '',
    skills: '',
    worktimes: '',
    selectedImage:null,
    previewImage:null,
    age:'',
    profile: '',
    uploading:false,
    role:''
  }

  componentDidMount() {
    firebase.dbRef.collection('companies').onSnapshot(snapshot => {
      const promises = snapshot.docs.map(async (doc) => {
        const data = doc.data();
        const sections = [];
        await firebase.dbRef.collection('companies').doc(doc.id).collection('avdelningar').get().then(snap => {
          snap.forEach(doc => {
            const sectionData = doc.data();
            sectionData.sectionId = doc.id;
            sections.push(sectionData);
          });
        });
        const company = {
          name: data.name,
          region: data.region,
          companyId: doc.id,
          sections: sections
        };
        return company;
      });
      Promise.all(promises).then(results => {
        this.setState({companies:results});
      })
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  uploadPhoto = (event) => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
        // Read files
        this.setState({
          selectedImage: event.target.files[0]
        })

        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result
          });
        }
        if (event.target.files[0]) {
          reader.readAsDataURL(event.target.files[0])
        }
    } else {
        alert('The File APIs are not fully supported by your browser.');
    }
  }

  validation = () => {
    const errors = {};
    if ((this.state.birthday.length !== 8) && (this.state.birthday.length !== 0)) {
      errors['birthday'] = true;
    }else {
      delete errors['birthday'];
    }
    if (this.state.firstName.length === 0) {
      errors['firstName'] = true;
    }else {
      delete errors['firstName'];
    }
    if (this.state.lastName.length === 0) {
      errors['lastName'] = true;
    }else {
      delete errors['lastName'];
    }
    if (this.state.selectedCompany === '') {
      errors['selectedCompany'] = true;
    }else {
      delete errors['selectedCompany'];
    }
    if (this.state.selectedSection === '') {
      errors['selectedSection'] = true;
    }else {
      delete errors['selectedSection'];
    }
    if (this.state.interviewDate.length === 0) {
      errors['interviewDate'] = true;
    }else {
      delete errors['interviewDate'];
    }
    this.setState({errors: errors});
    if (Object.keys(errors).length > 0) {
      return false;
    }else {
      return true;
    }
  }

  addNewPerson = () => {
    if (this.validation()) {
      this.setState({uploading:true});
      const fullName = this.state.firstName + ' ' + this.state.lastName;
      const personalNumber = this.state.birthday + '-' + this.state.fourLast;

      const combinedTitle = `${fullName}, ${this.state.age} - ${this.state.role}, ${this.state.selectedCompany.name}, ${this.state.selectedSection.name}`;
      const ref = firebase.dbRef.collection('people').doc();

      if (this.state.imagePreviewUrl && this.state.selectedImage) {
        const storageRef = firebase.storage().ref().child(this.state.selectedCompany.companyId).child('people/'+ref.id);
        storageRef.put(this.state.selectedImage).then(snap => {
          storageRef.getDownloadURL().then(url => {
            firebase.dbRef.collection('people').doc(ref.id).set({
              imageUrl: url,
              interviewDate: this.state.interviewDate,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              fullName: fullName,
              birthday: this.state.birthday,
              personalNumber: personalNumber,
              fourLast: this.state.fourLast,
              company: this.state.selectedCompany,
              department: this.state.selectedSection,
              companyName: this.state.selectedCompany.name,
              departmentName: this.state.selectedSection.name,
              region: this.state.selectedCompany.region,
              phoneNumber: this.state.phoneNumber,
              email: this.state.email,
              experiences: this.state.experiences,
              abilities: this.state.abilities,
              certificates: this.state.certificates,
              development: this.state.development,
              lean: this.state.lean,
              titel: this.state.role,
              combinedTitle: combinedTitle,
              documentId:ref.id,
              skills: this.state.skills,
              worktimes: this.state.worktimes,
              age: this.state.age,
              personRef: ref,
              profile: this.state.profile,
              addedByName: firebase.name,
              addedByUid: firebase.uid,
              lastUpdated: firebase.firestore.Timestamp.now(),
              lastUpdatedByName: firebase.name,
              lastUpdatedByUid: firebase.uid,
            }).then(() => {
              this.setState({uploading:false});
              this.props.history.push('/person/'+ref.id);
            });;
          })
        })
      }else {
        firebase.dbRef.collection('people').doc(ref.id).set({
          imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aia-app-b7b88.appspot.com/o/user-png-icon-big-image-png-2240.png?alt=media&token=4fa2a0a4-bb29-4b7f-b9ba-18c7fbc5e4b4',
          interviewDate: this.state.interviewDate,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          fullName: fullName,
          birthday: this.state.birthday,
          personalNumber: personalNumber,
          fourLast: this.state.fourLast,
          company: this.state.selectedCompany,
          department: this.state.selectedSection,
          companyName: this.state.selectedCompany.name,
          departmentName: this.state.selectedSection.name,
          region: this.state.selectedCompany.region,
          phoneNumber: this.state.phoneNumber,
          email: this.state.email,
          experiences: this.state.experiences,
          abilities: this.state.abilities,
          certificates: this.state.certificates,
          development: this.state.development,
          lean: this.state.lean,
          titel: this.state.role,
          combinedTitle: combinedTitle,
          documentId:ref.id,
          skills: this.state.skills,
          worktimes: this.state.worktimes,
          age: this.state.age,
          personRef: ref,
          profile: this.state.profile,
          addedByName: firebase.name,
          addedByUid: firebase.uid,
          lastUpdated: firebase.firestore.Timestamp.now(),
          lastUpdatedByName: firebase.name,
          lastUpdatedByUid: firebase.uid,
        }).then(() => {
          this.setState({uploading:false});
          this.props.history.push('/person/'+ref.id);
        });
      }
    }
  }

  render() {
    const { errors } = this.state;
    let counter = 0;
    let even = [];
    let odd = [];
    textAreas.forEach(area => {
      let inner;
      if (area.type === 'formated') {
        const error = errors['birthday'];
        inner = (
          <div className={'textFieldWrapper' + (area.class ? ' ' + area.class : '')}>
            <NumberFormat
              format={area.format}
              name={area.name}
              value={this.state[area.state]}
              onValueChange={vals => {
                if (vals.value.length === 8) {
                  const dateString = vals.value;
                  const year = dateString.substring(0,4);
                  const month = dateString.substring(4,6);
                  const day = dateString.substring(6,8);
                  const birthDate = new Date(year, month-1, day);
                  const age =  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
                  this.setState({age:age});
                }
                this.setState({[area.state]: vals.value})
              }}
              error={error}
              customInput={area.input}
             />
          </div>
        )
      }
      else if (area.type === 'fill') {
        inner = (<div className={'textFieldWrapper' + (area.class ? ' ' + area.class : '')} />)
      }
      else if (area.type === 'image') {
        inner = (
          <div className={'textFieldWrapper' + (area.class ? ' ' + area.class : '')}>
            <input type='file' name='profilePic' onChange={e => this.uploadPhoto(e)} accept="image/x-png,image/jpeg" />
          </div>
        )
      }
      else if (area.type === 'previewImage') {
        inner = (
          <div className={'textFieldWrapper' + (area.class ? ' ' + area.class : '')}>
            {this.state.imagePreviewUrl && <img alt='bild' src={this.state.imagePreviewUrl} className='previewImage' /> }
          </div>
        )
      }else if (area.type === 'selectCompany') {
        const error = errors['selectedCompany'];
        inner = (
          <div className='textFieldWrapper'>
            <TextField
              select
              error={error}
              label="Företag"
              //className='textField-select'
              value={this.state.selectedCompany.name}
              name='operatorLevel'
              onChange={(e) => this.setState({selectedCompany: e.target.value})}
              helperText="Välj företag"
              margin="normal"
              variant="outlined"
            >
              {this.state.companies.map(option => (
                <MenuItem className='selectMenu-item' key={option.name} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )
      }else if (area.type === 'selectSection') {
        const error = errors['selectedSection'];
        if (this.state.selectedCompany) {
          inner = (
            <div className='textFieldWrapper'>
              <TextField
                select
                error={error}
                label="Avdelning"
                value={this.state.selectedSection.name}
                name='operatorLevel'
                onChange={(e) => this.setState({selectedSection: e.target.value})}
                helperText="Välj avdelning"
                margin="normal"
                fullWidth={true}
                variant="outlined"
              >
                {this.state.selectedCompany.sections.map(option => (
                  <MenuItem className='selectMenu-item' key={option.name} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )
        }else {
          inner = (<div className='textFieldWrapper'/>);
        }

      }
      else {
        const error = errors[area.state];
        inner = (
          <div className={'textFieldWrapper' + (area.class ? ' ' + area.class : '')}>
            <TextField
              error={error}
              label={area.name}
              name={area.state}
              value={this.state[area.state]}
              onChange={(e) => this.handleChange(e)}
              multiline={area.multiline}
              rows={area.rows}
              className='textField'
              margin="normal"
              variant="outlined"
              placeholder={area.placeholder}
            />
          </div>
        )
      }

      if (counter % 2 === 0) {
        counter++;
        even.push(inner);
      }else {
        counter++;
        odd.push(inner);
      }
    });


    return(
      <div>
        <div>
          <h3>Lägg till ny person</h3>
          <TextField
            error={errors['interviewDate']}
            id="date"
            label="Senaste intervjudatum"
            type="date"
            //defaultValue={new Date()}
            value={this.state.interviewDate}
            onChange={(e) => this.setState({interviewDate:e.target.value})}
            className='textFieldContainer'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {even.map((area, i) => {
          return (
            <div key={i} className='textFieldContainer'>
              {area}
              {odd[i]}
            </div>
          )
        })}
        <div className='textFieldContainer'>

        </div>
        <div className='buttonsContainer'>
          <Button
            startIcon={this.state.uploading ? <CircularProgress color='white' size={20} /> : null}
            onClick={this.addNewPerson}
            variant="contained"
            color='primary'
            disabled={this.state.uploading}
            className='addButton_ui'>
            Lägg till
            <AddIcon style={{marginLeft: 10}} />
          </Button>
        </div>
        {Object.keys(this.state.errors).length > 0 && (<p style={{color:'red'}}>Vänligen fyll i all nödvändig information</p>)}

      </div>
    )
  }
}

export default withRouter(AddPerson);
