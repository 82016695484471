import React from 'react';

//MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Img from 'react-fix-image-orientation';

import { makeStyles } from '@material-ui/core/styles';

import NumberFormat from 'react-number-format';

import '../css/addperson.css';
import '../css/viewperson.css';

//router
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: 500,
  },
  rootperson: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'flex-start',
    width: 800,
  },
  gridRoot: {
    flexGrow:1
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  previewImagePerson: {
    maxWidth:'100%',
    maxHeight:70,
    padding:10,
    float:'left',
  },
  button: {
    margin: theme.spacing(1),
  },
}));


const PersonExpanded = ({ person }) => {
  const email = (<b>Email: </b>);
  const personnummber = (<b>Personnummer: </b>);
  const history = useHistory();
  const url = `/section/${person.company.companyId}/${person.department.sectionId}`;
  const update = person.lastUpdated;
  let updatedAt;
  if (update) {
    updatedAt = update.toDate()
  }
  return (
    <div id='personExpanded' style={{backgroundColor: 'white'}}>
      <div>
        <Typography variant="h5">
          {person.companyName} / <Link component='button' variant='h5' onClick={() => history.push(url)}>{person.departmentName}</Link>
        </Typography>
        <Img alt='bild' src={person.imageUrl} className='previewImage' />
        <Typography variant="h4">{person.fullName}, {person.age} - {person.titel}</Typography>
        <p><b>Telefon:</b> {person.phoneNumber} {person.email.length > 0 && email}{person.email} {person.birthday.length > 0 && personnummber}{person.birthday.length > 0 && person.personalNumber}</p>
        <p><b>Medlem sedan:</b> {person.interviewDate} | <b>Tillagd av:</b> {person.addedByName}</p>
        { updatedAt &&
          <p><i>Senast uppdaterad: {updatedAt.getFullYear()}-{updatedAt.getMonth()+1 > 9 ? updatedAt.getMonth()+1 : '0'+(updatedAt.getMonth()+1)}-{updatedAt.getDate() > 9 ? updatedAt.getDate() : '0'+updatedAt.getDate()} av {person.lastUpdatedByName}</i></p>
        }
      </div>
      <div className='profileDiv'>
        <Typography variant="h6">Profil och sammanfattning</Typography>
        <Typography>
          {person.profile.split("\n").map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
        </Typography>
        <div className='displayWrapper'>
          <div style={{flex:'0 0 50%', paddingRight: 10}}>
            <Typography variant="h6">Erfarenheter</Typography>
            <Typography>
              {person.experiences.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          </div>
          <div style={{flex:1, paddingLeft: 10}}>
            <Typography variant="h6">Kunskaper / Lean</Typography>
            <Typography>
              {person.lean.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          </div>
        </div>
        <div className='displayWrapper'>
          <div style={{flex:'0 0 50%', paddingRight: 10}}>
            <Typography variant="h6">Egenskaper</Typography>
            <Typography>
              {person.abilities.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          </div>
          <div style={{flex:1, paddingLeft: 10}}>
            <Typography variant="h6">Färdigheter</Typography>
            <Typography>
              {person.skills.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          </div>
        </div>
        <div className='displayWrapper'>
          <div style={{flex:'0 0 50%', paddingRight: 10}}>
            <Typography variant="h6">Arbetstider</Typography>
            <Typography>
              {person.worktimes.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          </div>
          <div style={{flex:1, paddingLeft: 10}}>
            <Typography variant="h6">Certifieringar</Typography>
            <Typography>
              {person.certificates.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
          </div>
        </div>
        <div style={{marginTop: 30}}>
          <Typography variant="h6">Mål och utveckling</Typography>
          <Typography>
            {person.development.split("\n").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}
          </Typography>
        </div>

      </div>
    </div>
  );
}

const PersonSearch = ({ person }) => {

  const classes = useStyles();
  const { _highlightResult } = person;
  const {
    abilities,
    certificates,
    experiences,
    lean,
    //profile,
    development,
    worktimes,
    skills,
    //fullName,
    //age,
    //companyName,
    //departmentName,
  } = _highlightResult;
  //console.log('person', person);
  const profileArray = [
    {value:abilities,key:'Egenskaper'},
    {value:certificates,key:'Certifieringar'},
    {value:experiences,key:'Erfarenheter'},
    {value:lean,key:'Kunskaper / Lean'},
    //{value:profile,key:'Profil & sammanfattning'},
    {value:development,key:'Mål & utveckling'},
    {value:worktimes,key:'Arbetstider'},
    {value:skills,key:'Färdigheter'}];
  const profileArrayMap = profileArray.filter(e => e.value.matchLevel !== 'none');
  let matchesJsx = profileArrayMap.map(prof => {
    return (<div>
      <Typography><b>{prof.key}</b></Typography>
      <Typography dangerouslySetInnerHTML={{__html: prof.value.value}} />
    </div>);
  });
  if (matchesJsx.length === 0) {
    matchesJsx =
      (<div>
        <Typography><b>Profil & sammanfattning</b></Typography>
        <Typography>{person.profile}</Typography>
      </div>);
  }
//{prof.value.replaceAll('<em>', '<b>').replaceAll('</em>','</b>')}
  const history = useHistory();

  return (
    <div className='PersonOuter' style={{marginTop: 10}} key={person.objectID}>
      <Paper className={classes.rootperson} onClick={() => {history.push('/person/'+person.objectID)}}>
        <div className='imgDivPerson'>
          <Img className='previewImagePerson' src={person.imageUrl} />
        </div>
        <div className='infoDisplay'>
          <Typography variant="h4">{person.fullName}, {person.age}</Typography>
          <Typography variant="h6">{person.titel}, {person.companyName}, {person.departmentName}</Typography>
          <div className='infoDisplay'>
            {matchesJsx}
          </div>
        </div>
      </Paper>
    </div>
  )
}

const PersonCondensed = ({ person }) => {

  const history = useHistory();
  const classes = useStyles();
  return (
    <div className='PersonOuter' style={{marginTop: 20}} key={person.id} onClick={() => {history.push('/person/'+person.id)}}>
      <Paper className={classes.root}>
        <div className='imgDivPerson'>
          <Img className={classes.previewImagePerson} src={person.imageUrl} />
        </div>
        <div className='infoDisplay'>
          <Typography variant="h6">{person.fullName}, {person.age} - {person.titel}</Typography>
        </div>
      </Paper>
    </div>
  )
}

export { PersonExpanded, PersonSearch, PersonCondensed };
