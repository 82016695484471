import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect
} from "react-router-dom";

//firebase
import firebase from './Firebase';

//Auth
import Auth from './pages/Auth';
//Pages
import Search from './pages/Search';
import CompanySection from './pages/CompanySection';
import AddPerson from './pages/AddPerson';
import ViewPerson from './pages/ViewPerson';
import Section from './pages/Section';

//MATERIAL
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

//compo
import Alert from './components/Alert';

class App extends React.Component {

  state = {
    loggedIn:false,
    supportAlert:false,
    lastSearchState: null,
  }
  setSearchState = (searchState) => {
    this.setState({lastSearchState: searchState});
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.user = user;
        firebase.db.collection('users').doc(user.uid).get().then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            firebase.dbRef = firebase.db.collection('companies').doc(data.company);
            firebase.uid = user.uid;
            firebase.name = data.name;
            if (data.internalCompany) {
              firebase.internalCompany = data.internalCompany;
            } else {
              firebase.internalCompany = data.company;
            }
            this.setState({loggedIn:true});
          }else {
            this.setState({loggedIn:false});
          }
        });
      } else {
        this.setState({loggedIn:false});
      }
    });
  }

  render() {

    if (this.state.loggedIn) {
      return (
        <Router>
          <div className="Wrapper">
            <Alert
              title='Hjälp & Förbättringar'
              open={this.state.supportAlert}
              noCancel
              confirmText='Stäng'
              description='Vänligen kontakta support@agileindustrial.se för support samt för förbättringar med plattformen.'
              result={(result) => {
                this.setState({supportAlert:false});
              }}
            />
            <div id="topBar_placeholder">
              <div id="topBar">
                <h5 style={{margin:0}}>AIA</h5>
              </div>
              <div style={{zIndex: 500, position: 'fixed'}}>
                <Button variant='contained' color='primary'
                  onClick={() => {
                    firebase.auth().signOut().then(function() {
                      // Sign-out successful.
                    }, function(error) {
                      // An error happened.
                    });
                  }}
                  >
                  Logga ut
                </Button>
              </div>
            </div>
            <div className="clearfix">
              <div>
                <div id="MenuWrapper">
                  <nav>
                    <ul id='mainMenu'>
                      <NavLink className='menuItem' activeClassName='menuItem-is-active' to="/search">Sök</NavLink>
                      <NavLink className='menuItem' activeClassName='menuItem-is-active' to="/section">Företag/Avdelning</NavLink>
                      <NavLink className='menuItem' activeClassName='menuItem-is-active' to="/add-person">Lägg till person</NavLink>
                      <Button onClick={() => this.setState({supportAlert:true})}
                        startIcon={<HelpOutlineIcon />} color='primary' style={{margin: 2}}>
                        Hjälp & Förbättringar
                      </Button>
                    </ul>
                  </nav>
                  <div className='spacing' />
                </div>
                <div id='Content'>
                  <div id='innerContent'>
                  {/* A <Switch> looks through its children <Route>s and
                      renders the first one that matches the current URL. */}
                    <Switch>
                      <Route exact path='/auth'>
                        <Auth />
                      </Route>
                      <Route exact path="/section">
                        <CompanySection />
                      </Route>
                      <Route exact path="/add-person">
                        <AddPerson />
                      </Route>
                      <Route path="/search/:lastSearch?">
                        <Search setSearchState={this.setSearchState} searchState={this.state.lastSearchState}/>
                      </Route>
                      <Route path="/person/:id">
                        <ViewPerson />
                      </Route>
                      <Route path='/section/:companyId/:sectionId'>
                        <Section />
                      </Route>
                      <Redirect from='*' to='/search' />
                    </Switch>
                </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      );
    }else {
      return <Auth />
    }
  }

}

export default App;
