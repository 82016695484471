const firebase = require('firebase');

const config = {
    apiKey: "AIzaSyDcesj9LW1VHSQ3THCytx3ZcuYHZURoL1I",
    authDomain: "aia-app-b7b88.firebaseapp.com",
    databaseURL: "https://aia-app-b7b88.firebaseio.com",
    projectId: "aia-app-b7b88",
    storageBucket: "aia-app-b7b88.appspot.com",
    messagingSenderId: "828227463015",
    appId: "1:828227463015:web:2b855e5db2b6126bdbb1c2",
    measurementId: "G-9JTXJDTEM3"
};

firebase.initializeApp(config);
firebase.analytics();


firebase.db = firebase.firestore();

module.exports = firebase;
