import React, { Component } from 'react';
import '../css/auth.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import ForgotPass from '../components/ForgotPass'

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import firebase from '../Firebase';

const classes = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default class Auth extends Component {

  state = {
    email:'',
    password:'',
    error:null,
    forgotPass:false
  }

  render() {
    return (
      <div className='outer'>
        <ForgotPass
          open={this.state.forgotPass}
          handleClose={() => this.setState({forgotPass: false})}
        />
        <div className='inner'>
          <div className='logo'>
            <h1>Agile Industrial Alliance </h1>
          </div>
          <div className='login'>
            <div className='form-email'>
              <TextField
                error={this.state.error}
                id="standard-basic"
                label="Email"
                fullWidth
                onChange={(e) => {this.setState({email:e.target.value})}}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton onClick={() => this.setState({forgotPass:true})} color="primary" className={classes.iconButton} aria-label="directions">
                <HelpIcon />
              </IconButton>
            </div>
            <div className='form-password'>
              <TextField
                error={this.state.error}
                id="standard-basic"
                label="Password"
                type='password'
                fullWidth
                onChange={(e) => {this.setState({password:e.target.value})}}
                helperText={this.state.error}
              />
            </div>
            <div className='Login'>
              <Button
                startIcon={this.state.loggingIn ? <CircularProgress color='white' size={20} /> : null}
                 variant='contained' color='primary' onClick={() => {
                   this.setState({loggingIn:true})
                firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch((error) => {
                  // Handle Errors here.
                  this.setState({error:error.message,loggingIn:false});
                  // ...
                });
              }}>
                Log in
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
