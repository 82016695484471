import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Alert = ({ title, description, result, open, confirmText = 'Jag är säker', cancelText = 'Avbryt', noCancel = false }) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => result(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        {description && (<DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>)}
        <DialogActions>
          <Button onClick={() => result(true)} color="primary">
            {confirmText}
          </Button>
          {!noCancel &&
          <Button onClick={() => result(false)} color="secondary" autoFocus>
            {cancelText}
          </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Alert;
