import React, { Component } from 'react';

import firebase from '../Firebase.js';

import { withRouter } from 'react-router';
import {
  Switch,
  Route,
} from "react-router-dom";

//material UI
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Export from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';

import Alert from '../components/Alert';

//person
import { PersonExpanded } from '../components/Person';

//editing
import EditPerson from './EditPerson';

function PrintElem(elementId = 'personExpanded')
{
  var printContents = document.getElementById(elementId).innerHTML;
  //var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  window.location.href = window.location.href;
}

class ViewPerson extends Component {
  state = {
    person: null,
    alert:false,
    editAlert:false,
    editing:false,
    originalMatch:''
  }
  componentDidMount() {
    const { match, location } = this.props;
    const { id } = match.params;
    const editing = location.pathname.includes('edit');
    this.setState({originalMatch: match.url, editing: editing});
    firebase.dbRef.collection('people').doc(id).get().then(personData => {
      const person = personData.data();

      let fourLast = person.fourLast;
      if (!fourLast) {
        const split = person.personalNumber ? person.personalNumber.split('-') : false;
        if (split && split.length > 0) {
          fourLast = split[1];
        }
      }
      person.fourLast = fourLast;
      this.setState({person:person});
    });
  }
  deletePerson = () => {
    const { id } = this.props.match.params;
    firebase.dbRef.collection('people').doc(id).delete().then(() => {
      this.props.history.push('/search');
    })
  }
  finishEditing = () => {
    this.setState({
      editing:false,
      editAlert:false,
    });
    this.props.history.push(this.state.originalMatch);
  }
  startEditing = () => {
    this.setState({editing:true});
    this.props.history.push(`${this.state.originalMatch}/edit`);
  }
  render() {
    const { person, editing, editAlert } = this.state;
    const { match } = this.props;
    if (!person ) {
      return <div><h5>Loading...</h5></div>
    }
    return (
      <div>
        <Alert
          title='Varning'
          open={this.state.alert}
          description='Är du säker på att du vill ta bort personen ur systemet?'
          result={(result) => {
            if (result) {
              this.deletePerson();
            }
            this.setState({alert:false});
          }}
        />
        <Alert
          title='Avsluta redigering'
          open={editAlert}
          description='Är du säker på att du vill avsluta redigeringen? Alla ändringar kommer försvinna.'
          result={(result) => {
            if (result) {
              this.finishEditing();
            }
            this.setState({editAlert:false});
          }}
        />
        <div style={{position: 'absolute', padding: 20, right:0, top:0}}>
          {editing ? (
            <Button onClick={() => this.setState({editAlert:true})}
              startIcon={<EditIcon />} variant='contained' color='secondary' style={{margin: 2}}>
              Avsluta redigering
            </Button>
          ) : (
            <div>
              <Switch>
                <Route exact path={`${match.path}`}>
                  <Button onClick={() => PrintElem()}
                    startIcon={<Export />} variant='contained' color='primary' style={{margin: 2}}>
                    Exportera
                  </Button>
                </Route>
              </Switch>
              <Button onClick={() => this.startEditing()}
                startIcon={<EditIcon />} variant='contained' color='primary' style={{margin: 2}}>
                Redigera person
              </Button>
              <Button onClick={() => this.setState({alert:true})} startIcon={<DeleteIcon />} style={{margin: 2}} variant='contained' color='secondary'>
                Ta bort
              </Button>
            </div>
          ) }
        </div>
        <Switch>
          <Route exact path={`${match.path}`}>
            <PersonExpanded person={person} />
          </Route>
          <Route exact path={`${match.path}/edit`}>
            <EditPerson
              {...person}
              id={match.params.id}
            />
          </Route>
        </Switch>

      </div>

    )
  }
}

export default withRouter(ViewPerson);
