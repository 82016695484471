import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import firebase from '../Firebase';

const ForgotPass = ({ open, handleClose }) => {

  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Glömt lösenord</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Skriv in den email som är associerad med ditt konto för att få en länk där du kan återställa ditt lösenord.
          </DialogContentText>
          <TextField
            error={error.length > 0 ? true : false}
            helperText={error}
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={() => {
            firebase.auth().sendPasswordResetEmail(email).then(function() {
              // Email sent.
              handleClose()
            }).catch(function(error) {
              // An error happened.
              setError(error.message)
            });

          }} color="primary">
            Skicka återställningslänk
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ForgotPass;
